// Here you can add other styles
input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    color: var(--themeclr);
}

input[type=file],

/* FF, IE7+, chrome (except button) */

input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
}

input[type="date"],
input[type="month"],
input[type="week"] {
    position: relative;
}

input[type="date"]:after,
input[type="month"]:after,
input[type="week"]:after {
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f073";
    -moz-content: "\f073";
    color: var(--themeclr);
    padding: 0 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
}

input.form-control.input_text.date-field1 {
    align-items: flex-start;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="week"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
}

a {
    text-decoration: none;
    color: var(--themeclr);

    &:hover {
        text-decoration: none;
        color: var(--themeclr);
    }
}

.box_shadow {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

ul {
    padding: 0;

    li {
        list-style: none;
    }
}

.flex_end {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.dropdown-item {
    i {
        color: var(--themeclr);
    }
}

.app-body {
    overflow-x: unset;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: var(--themeclr);
    background-color: var(--themeclr);
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--themeclr);
}

.navbar-brand {
    display: inline-block !important;
    padding: 5px 0px !important;
}

.sidebar {
    background-color: #3e429d;

    .nav-link {
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        padding-left: 45px;
        padding-right: 24px;

        &:hover {
            background-color: var(--themeclr);

            .nav-icon {
                color: #fff !important;
            }
        }

        &.active {
            color: var(--themeclr);
            background-color: #fff;
            border-right: 0.5px solid var(--themeclr);

            .nav-icon {
                color: var(--themeclr) !important;
            }
        }

        .nav-icon {
            font-weight: 800;
            font-size: 18px !important;
            color: #fff !important;
            margin-left: -32px;
        }
    }
}

.nav-dropdown .nav-link.active {
    color: var(--themeclr) !important;
}

button[type="button"].navbar-toggler {
    display: none !important;
}


/*------------- Table Css -----------*/

.card {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.table_header {
    display: flex;
    justify-content: space-between;

    .right_col {
        // display: flex;
    }

    .search_form {
        .from-control {
            height: 35px;
            padding: 5px;
            border: 1px solid #ddd;

            &:focus,
            :active {
                border: 1px solid var(--themeclr);
                outline: none !important;
            }
        }

        @media(max-width:1200px) {
            .from-control {
                width: 130px;
            }
        }

        button {
            background-color: var(--themeclr);
            height: 35px;
            border: 1px solid var(--themeclr);
            color: #fff;
            padding: 5px 10px;

            &:focus {
                outline: none;
            }
        }
    }
}

thead,
tbody {
    display: block;
}

.data_table {
    tr {
        display: flex;
        border-bottom: 1px solid $gray-border;
        position: relative;
        cursor: initial !important;

        th {
            border-top: none;
            border-bottom-width: 0px;
            flex: 1;
            padding: 12px;
        }

        td {
            vertical-align: middle;
            border-top: none;
            flex: 1;
            padding: 12px 12px;
            text-align: left;
            word-break: break-word !important;

            @include screen ('tablet-wide') {
                word-break: break-word !important;
            }

            @include screen ('med-wide') {
                word-break: break-word !important;
            }
        }
    }

    tbody tr {
        align-items: baseline;
        transition: all 0.2s ease-in-out 0s;

        &:hover {
            background-color: #f8f8f8;
            cursor: pointer;
        }
    }
}

.data_table tr td:first-child {
    word-break: break-all;
}

.custom_scroll {
    form {
        display: flex;
        flex-wrap: wrap;
    }
}

.page-item.active {
    .page-link {
        z-index: 1;
        color: #fff;
        background-color: var(--themeclr) !important;
        border-color: var(--themeclr);
    }
}

.page-link {
    color: var(--themeclr);

    &:hover {
        color: #000;
    }
}

.data_form,
.profile_info {
    .card-header {
        background-color: var(--themeclr);
        color: #fff;
        font-size: 18px;
    }

    .tab-content {
        border: none;
    }
}


/*---------- Custom Scroll Css ------------*/

.card-group {
    margin-top: 20px;

    .card_box {
        width: 100%;

        .card_title {
            padding: 10px 0;
            margin: 0;
            border-bottom: 2px solid var(--themeclr);
        }

        .card_description {
            padding: 0 3rem;

            p {
                font-size: 17px;
                line-height: 30px;
                text-align: justify;
            }
        }

        .card_list li {
            font-size: 17px;
            margin-bottom: 20px;
            color: #000;
        }
    }
}

.ngx-pagination {
    margin-bottom: 0 !important;

    .current {
        background-color: var(--themeclr) !important;
    }
}

.close_icon {
    color: #fff !important;
    opacity: 1 !important;
    font-size: 24px !important;

    &:focus {
        outline: none;
    }
}

@keyframes check {
    0% {
        height: 0;
        width: 0;
    }

    25% {
        height: 0;
        width: 10px;
    }

    50% {
        height: 20px;
        width: 10px;
    }
}

.list_checkbox {
    border-top: 1px solid #eee;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
}

.checkbox {
    background-color: #fff;
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    float: right;
}

.permission_grp {
    margin-left: 15px;

    @include screen ('tablet') {
        margin-left: 0px;
    }

    .permission_title {
        font-size: 22px;
        padding: 5px;
        color: var(--themeclr);
        margin-left: -35px;

        @include screen ('tablet') {
            margin-left: -15px;
        }
    }
}

.checkbox span {
    display: block;
    height: 20px;
    position: relative;
    width: 20px;
    padding: 0;
    z-index: 999;
}

.checkbox span:after {
    transform: scaleX(-1) rotate(135deg);
    transform-origin: left top;
    border-right: 3px solid #fff;
    border-top: 3px solid #fff;
    content: "";
    display: block;
    height: 16px;
    left: 1px;
    position: absolute;
    top: 10px;
    width: 8px;
}

.list-group-item span {
    word-break: break-word;
    width: 80%;
    display: inline-block;

    @include screen ('mobile') {
        width: 80%;
    }
}

.checkbox input {
    display: none
}

.checkbox input:checked+span:after {
    -webkit-animation: check .8s;
    -moz-animation: check .8s;
    -o-animation: check .8s;
    animation: check .8s;
    border-color: #eee
}

.checkbox input:checked+.default:after {
    border-color: var(--themeclr);
}

#custom_upload_btn input {
    opacity: 0;
    position: relative;
    z-index: 9;
}

#custom_upload_btn span {
    border: 1px solid var(--themeclr);
    padding: 5px 15px;
    color: var(--themeclr);
    border-radius: 5px;
    position: absolute;
    left: 15px;
    line-height: 1.8;
}

.edit_icon {
    position: absolute;
    bottom: 0;
    right: 7px;
    font-size: 24px;

    &::after {
        content: "";
        border-bottom: 35px solid var(--themeclr);
        position: absolute;
        bottom: 0;
        right: -7px;
        border-right: 0 solid transparent;
        border-left: 40px solid transparent;
        width: 120px;
        z-index: 1;
    }

    a {
        color: var(--themeclr);
        position: relative;
        z-index: 9;
        font-size: 16px;
        background-color: #fff;
        width: 25px;
        height: 25px;
        display: inline-block;
        text-align: center;
        border-radius: 5px;
        bottom: 3px;
        margin-right: 5px;
        line-height: 25px;
    }
}

.guarantor_profile {
    display: flex;
    flex-direction: column;

    .row {
        padding: 12px 0;
    }

    p {
        margin-bottom: 0;
    }
}

#requestInfo .guarantor_profile {
    flex-direction: inherit;
}

.card_profile {
    .profile_img {
        width: 80px;
        height: 80px;
        cursor: pointer;
        border: 0;
        border-radius: 0;
        margin: 15px 0 10px;
        box-shadow: 0 2px 5px var(--themeclr);
        justify-content: center;
        align-items: center;
        display: flex;

        figure {
            margin-bottom: 0;
        }

        .upload-btn-wrapper {
            top: 5px;
            right: -12px;

            i {
                height: 30px;
                width: 30px;
                font-size: 16px;
            }
        }
    }
}

.img-box {
    position: fixed;
    left: 0;
    top: 0;
    transform: scale(0);
    transform-origin: center;
    transition: all 0.4s ease-in 0s;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    z-index: 99;

    .img-box-item {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10%;

        img {
            width: 450px;
        }
    }

    .close_btn {
        position: absolute;
        color: var(--themeclr);
        z-index: 999;
        top: 70px;
        right: 25px;
        background-color: #fff;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        font-size: 18px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.tab-content {
    background-color: transparent !important;

    h3 {
        background-color: #fff;
        padding: 15px 10px;
        box-shadow: 0 2px 5px #ccc;
        color: var(--themeclr);
        font-size: 1.125rem;

        @include screen ('tablet-wide') {
            font-size: 0.875rem;
        }
    }
}

.table_content {
    padding: 1rem;
    background-color: #fff;
    transition: var(--transition);

    .row {
        border-bottom: 1px solid #eee;
        padding: 10px 0;

        &:hover {
            background-color: #f8f8f8;
        }
    }

    p {
        margin: 0;
    }
}

.nav-dropdown-items {
    .sub-menu {
        a {
            border-bottom: 0;
            margin-left: 20px;
            border-left: 2px solid rgba(255, 255, 255, 0.3) !important;
            padding-left: 22px;
            position: relative;

            &::after {
                content: "";
                border-top: 2px solid rgba(255, 255, 255, 0.3);
                position: absolute;
                top: 50%;
                left: 0;
                width: 15px;
            }
        }
    }
}

.nav-dropdown-items .sub-menu:last-child a {
    border-left: 0 !important;
}

.nav-dropdown-items .sub-menu:last-child a::before {
    content: "";
    border-left: 2px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    height: 50%;
    left: 0;
}

.nav-dropdown .sub-menu .nav-link.active {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);
}

.cropping_img img-cropper {
    display: flex;
}

.card-list-table tbody tr:nth-child(odd) {
    background-color: #f0f3f8;
    transition: var(--transition);

    &:hover {
        background-color: #f8f8f8;
    }
}

.table_content .row:nth-child(odd) {
    transition: var(--transition);

    &:hover {
        background-color: #f8f8f8;
    }
}

.cropping_img canvas {
    cursor: pointer;
}

.profile_icon {
    border: 2px solid var(--themeclr) !important;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card_box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        height: 60px;
        width: 60px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        padding: 3px;
        border-radius: 50%;
    }
}

.app-header .nav-item {
    min-width: auto;
    margin: 0 10px;
}

.app-header .nav-item .nav-link .badge {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    left: 4px;
    padding: 2px 5px;
}

.dropdown_title {
    font-size: 0.765625rem;
    font-weight: 400;
    padding: 10px;
    border-bottom: 1px solid #eee;
    background-color: var(--themeclr);
    text-align: center;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
}

.accountDrop .dropdown-menu ul li a {
    display: inline-block;
    color: #000;
    font-weight: 400;
    padding: 9px 15px;
    font-size: 15px;
    border-bottom: 1px solid #eee !important;
    width: 100%;
    white-space: nowrap;
}

.dropdown_menu_noti {
    padding: 0;
    top: 35px;
    width: 300px;
    transform: translate(-80%);

    &::before {
        content: "";
        position: absolute;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid var(--themeclr);
        top: -10px;
        z-index: 9999;
        right: 0;
        transform: translate(-50%);
    }
}

.notification li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 0.3rem;
    align-items: center;

    strong {
        color: var(--themeclr);
        font-size: 12px;
        font-weight: 600;
    }

    span {
        font-size: 10px;
    }
}

.notification li:nth-child(odd) {
    background-color: #f0f5f8;
}

.w-auto {
    width: auto;
}

.box_item {
    background-color: #fff;
    border-radius: 5px;
    color: #000;
    padding: 10px 20px;
    transition: var(--transition);
    margin-top: 25px;

    p {
        margin-bottom: 0;
        font-size: 18px;
    }
}

.switch-success .switch-input:checked+.switch-slider {
    background-color: var(--themeclr);
    border-color: var(--themeclr);
}

.switch-success .switch-input:checked+.switch-slider::before {
    border-color: var(--themeclr);
}

.flex_end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto !important;
}

.document_img {
    width: 100% !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    min-height: 200px !important;
    padding: 0 !important;

    @include screen ('mobile') {
        min-height: 135px !important;
    }

    @include screen ('tablet') {
        min-height: 140px !important;
    }
}

.icon_cylinder .nav-link::before {
    content: "";
    background: url("../assets//img//gas.svg");
    position: absolute;
    left: 12px;
    top: 15px;
    width: 19px;
    height: 30px;
    background-repeat: no-repeat;
}

.icon_cylinder .nav-link {
    padding-left: 40px;
}

.sidebar .icon_cylinder .nav-link.active::before {
    filter: invert(0.4) sepia(1) saturate(20) hue-rotate(360deg) brightness(1.1);
}

.order_icon .nav-link::before {
    content: "";
    background-image: url(../assets/img/avatars/order.svg) !important;
    width: 22px;
}

.refill_order .nav-tabs .nav-link.active {
    background-color: transparent !important;
    color: #f58634 !important;
    font-weight: 600;
    border-bottom: 2px solid var(--themeclr);
    border-left: 0;
    border-right: 0;
    border-top: 0;
    font-size: 16px;
}

.refill_order .nav-tabs .nav-link:hover,
.refill_order .nav-tabs .nav-link:focus {
    border-color: #fff;
}

.refill_order+.search_form {
    margin-right: 0 !important;
}

.view_link {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    opacity: 0;
}

.icon_table {
    position: relative;
    z-index: 13;
}

.box_item {
    .dashboard_icon {
        font-size: 30px;
        background-color: var(--themeclr);
        color: #fff;
        height: 55px;
        width: 55px;
        text-align: center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -35px auto 0;

        img {
            width: 32px;
        }
    }

    h4 {
        color: #888;
        font-size: 20px;
        margin-bottom: 0;
    }

    .box_content {
        display: flex;
        justify-content: space-between;
        margin: 15px 0 0px;

        p {
            font-size: 20px;
            color: var(--secondaryclr);
            font-weight: 700;
        }
    }
}

.sidebar-minimized .sidebar .icon_cylinder a {
    padding-left: 55px;
}

.sidebar-minimized .nav-link .nav-icon {
    margin-left: 0;
}

.watermark-logo::after {
    content: "";
    background-image: url("../assets/img/brand/applogo1.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    width: calc(100% - 200px);
    z-index: -1;
    background-size: 280pt;
    position: absolute;
    right: 0;
    bottom: 62px;
    height: 100%;
    opacity: 0.1;
}

.jspPane:focus,
.ks-items:focus {
    outline: none;
}

.wht_space {
    white-space: break-spaces;
    word-break: normal;
    width: 190px;
    display: block;
}


/****** Chat model Design **********/

.ks-messenger {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    height: 100%
}

.ks-messenger .ks-discussions {
    background: #fff;
    width: 340px;
    border-right: 1px solid #dee0e1
}

.ks-messenger .ks-discussions>.ks-search {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #dee0e1
}

.ks-messenger .ks-discussions>.ks-search>.input-icon {
    position: relative;
    width: 100%;
    margin: 0 15px;
}

.ks-messenger .ks-discussions>.ks-search .icon-addon {
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--themeclr);
    height: 34px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item {
    border-bottom: 1px solid #dee0e1;
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #333;
    padding: 10px;
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-group-amount {
    position: relative;
    top: 1px;
    margin-right: 12px;
    width: 32px;
    height: 32px;
    background-color: rgba(57, 81, 155, .1);
    text-align: center;
    line-height: 32px;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-group-amount>.ks-badge {
    position: absolute;
    bottom: -1px;
    right: -3px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-avatar {
    position: relative;
    top: 3px;
    margin-right: 12px;
    border: 2px solid var(--themeclr);
    border-radius: 50%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-avatar>img {
    width: 36px;
    height: 36px;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-avatar>.ks-badge {
    position: absolute;
    bottom: -3px;
    right: -3px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body>.ks-message {
    font-size: 12px;
    color: #858585;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body>.ks-message>img {
    position: relative;
    top: -2px;
    width: 18px;
    height: 18px;
    margin-right: 5px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body>.ks-name {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    margin-bottom: 4px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body>.ks-name>.ks-datetime {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    color: #858585;
    position: relative;
    top: 3px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-active {
    background: #f0f3f8;
    color: #333;
    position: relative
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-active::before {
    content: '';
    width: 4px;
    height: 100%;
    background: var(--themeclr);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-unread {
    background: rgba(247, 202, 24, .1);
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-unread>a>.ks-body>.ks-message {
    color: #333;
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-unread>a>.ks-group-amount {
    background-color: rgba(245, 154, 45, 1);
    color: #fff;
}

.ks-messenger .ks-messages,
.ks-messenger__messages {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background: #fff
}

.ks-messenger .ks-messages>.ks-header,
.ks-messenger__messages>.ks-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #dee0e1;
    padding: 9px 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.ks-messenger .ks-messages>.ks-header>.ks-description>.ks-name,
.ks-messenger__messages>.ks-header>.ks-description>.ks-name {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 5px;
    font-weight: 500
}

.ks-messenger .ks-messages>.ks-header>.ks-description>.ks-amount,
.ks-messenger__messages>.ks-header>.ks-description>.ks-amount {
    color: #858585;
    font-size: 12px;
    line-height: 12px
}

.ks-messenger .ks-messages>.ks-body,
.ks-messenger__messages>.ks-body {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.ks-messenger .ks-messages>.ks-body .ks-items,
.ks-messenger__messages>.ks-body .ks-items {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 20px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-separator,
.ks-messenger__messages>.ks-body .ks-items>.ks-separator {
    color: #858585;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 15px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item,
.ks-messenger__messages>.ks-body .ks-items>.ks-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    margin-bottom: 12px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item:last-child,
.ks-messenger__messages>.ks-body .ks-items>.ks-item:last-child {
    margin-bottom: 0
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body {
    font-size: 12px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 12px 15px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: relative
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-header,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 2px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-header>.ks-name,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-header>.ks-name {
    font-weight: 500
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-header>.ks-datetime,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-header>.ks-datetime {
    font-size: 10px;
    text-transform: uppercase;
    color: #858585
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-link,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-link {
    position: relative;
    margin-top: 10px;
    padding-left: 12px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-link:before,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-link:before {
    content: '';
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(57, 81, 155, .2)
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files {
    list-style: none;
    padding: 0;
    margin: 0
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file {
    float: left;
    margin-top: 15px;
    margin-right: 15px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a {
    display: block;
    color: #333;
    vertical-align: top
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info,
.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-thumb,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-thumb {
    float: left
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-thumb,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-thumb {
    margin-right: 5px;
    text-align: center
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-thumb>.ks-icon,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-thumb>.ks-icon {
    font-size: 36px;
    line-height: 36px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-thumb>img,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-thumb>img {
    width: 36px;
    height: 36px;
    -webkit-border-radius: 2px;
    border-radius: 2px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info>.ks-name,
.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info>.ks-size,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info>.ks-name,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info>.ks-size {
    display: block
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info>.ks-name,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info>.ks-name {
    font-size: 12px;
    margin-bottom: 2px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info>.ks-size,
.ks-messenger__messages>.ks-body .ks-items>.ks-item>.ks-body>.ks-message>.ks-files>.ks-file a>.ks-info>.ks-size {
    font-size: 10px;
    text-transform: uppercase;
    color: #858585
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-self>.ks-avatar,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-self>.ks-avatar {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-self>.ks-body,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-self>.ks-body {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    border: solid 1px var(--themeclr);
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 14px;
    margin-right: 65px
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-self>.ks-body:before,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-self>.ks-body:before {
    content: '';
    display: block;
    position: absolute;
    left: -10px;
    top: -1px;
    width: 0;
    height: 0;
    border-top: 10px solid var(--themeclr);
    border-right: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 10px solid transparent
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-self>.ks-body:after,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-self>.ks-body:after {
    content: '';
    display: block;
    position: absolute;
    left: -8px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 10px solid transparent
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-from>.ks-avatar,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-from>.ks-avatar {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-from>.ks-body,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-from>.ks-body {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    background-color: #eff1f7;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: 25px;
    margin-left: 55px;
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-from>.ks-body:before,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-from>.ks-body:before {
    content: '';
    display: block;
    position: absolute;
    right: -10px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #eff1f7
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-unread>.ks-body,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-unread>.ks-body {
    background: #fcf8e7
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-unread.ks-self>.ks-body:after,
.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-unread.ks-self>.ks-body:before,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-unread.ks-self>.ks-body:after,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-unread.ks-self>.ks-body:before {
    border-top: 10px solid #fcf8e7
}

.ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-unread.ks-from>.ks-body:before,
.ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-unread.ks-from>.ks-body:before {
    border-left: 10px solid #fcf8e7
}

.ks-messenger .ks-messages>.ks-footer,
.ks-messenger__messages>.ks-footer {
    padding: 15px 0 15px 50px;
    border-top: 1px solid #dee0e1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex
}

.ks-messenger .ks-messages>.ks-footer>.form-control,
.ks-messenger__messages>.ks-footer>.form-control {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 38px;
    overflow: hidden;
    resize: none;
    margin-right: 20px
}

.ks-messenger .ks-messages>.ks-footer>.ks-controls,
.ks-messenger__messages>.ks-footer>.ks-controls {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.ks-messenger .ks-messages>.ks-footer>.ks-controls .ks-attachment,
.ks-messenger .ks-messages>.ks-footer>.ks-controls .ks-smile,
.ks-messenger__messages>.ks-footer>.ks-controls .ks-attachment,
.ks-messenger__messages>.ks-footer>.ks-controls .ks-smile {
    font-size: 22px;
    color: #8997c3;
    line-height: 22px;
    margin-left: 25px
}

.ks-messenger .ks-messages>.ks-footer>.ks-controls>.dropdown,
.ks-messenger__messages>.ks-footer>.ks-controls>.dropdown {
    display: inline-block
}

.ks-messenger .ks-messages>.ks-footer>.ks-controls>.dropdown>.ks-smile,
.ks-messenger__messages>.ks-footer>.ks-controls>.dropdown>.ks-smile {
    padding: 0
}

.ks-messenger .ks-messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys,
.ks-messenger__messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys {
    width: 200px;
    height: 167px
}

.ks-messenger .ks-messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys .ks-smileys-wrapper,
.ks-messenger__messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys .ks-smileys-wrapper {
    padding: 10px
}

.ks-messenger .ks-messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys table,
.ks-messenger__messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys table {
    width: 100%
}

.ks-messenger .ks-messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys table td,
.ks-messenger__messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys table td {
    vertical-align: middle;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer
}

.ks-messenger .ks-messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys table tr:last-child td,
.ks-messenger__messages>.ks-footer>.ks-controls>.dropdown>.ks-smileys table tr:last-child td {
    padding-bottom: 0
}

.ks-messenger .ks-messages>.ks-files,
.ks-messenger__messages>.ks-files {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 20px;
    padding-top: 0;
    padding-bottom: 10px;
    margin-top: -10px
}

.ks-messenger .ks-messages>.ks-files>.ks-file,
.ks-messenger__messages>.ks-files>.ks-file {
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    position: relative
}

.ks-messenger .ks-messages>.ks-files>.ks-file:hover>.ks-thumb,
.ks-messenger__messages>.ks-files>.ks-file:hover>.ks-thumb {
    border: solid 1px #42a5f5
}

.ks-messenger .ks-messages>.ks-files>.ks-file>.ks-thumb,
.ks-messenger__messages>.ks-files>.ks-file>.ks-thumb {
    width: 90px;
    height: 90px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: #fff;
    border: solid 1px #dee0e1;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    font-size: 45px;
    color: #25628f
}

.ks-messenger .ks-messages>.ks-files>.ks-file>.ks-thumb::before,
.ks-messenger__messages>.ks-files>.ks-file>.ks-thumb::before {
    width: 100%
}

.ks-messenger .ks-messages>.ks-files>.ks-file>img.ks-thumb,
.ks-messenger__messages>.ks-files>.ks-file>img.ks-thumb {
    border: none
}

.ks-messenger .ks-messages>.ks-files>.ks-file>.ks-name,
.ks-messenger__messages>.ks-files>.ks-file>.ks-name {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #333
}

.ks-messenger .ks-messages>.ks-files>.ks-file>.ks-size,
.ks-messenger__messages>.ks-files>.ks-file>.ks-size {
    position: relative;
    top: -2px;
    font-size: 10px;
    color: #858585
}

.ks-messenger .ks-info,
.ks-messenger__info {
    width: 280px;
    background: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-left: 1px solid #dee0e1
}

.ks-messenger .ks-info>.ks-header,
.ks-messenger__info>.ks-header {
    border-bottom: 1px solid #dee0e1;
    line-height: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    padding: 20px
}

.ks-messenger .ks-info>.ks-body,
.ks-messenger__info>.ks-body {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 20px
}

.ks-messenger .ks-info>.ks-body>.ks-item+.ks-item,
.ks-messenger__info>.ks-body>.ks-item+.ks-item {
    margin-top: 10px;
    word-break: break-word;
}

.ks-messenger .ks-info>.ks-body>.ks-item.ks-user,
.ks-messenger__info>.ks-body>.ks-item.ks-user {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex
}

.ks-messenger .ks-info>.ks-body>.ks-item.ks-user>.ks-avatar,
.ks-messenger__info>.ks-body>.ks-item.ks-user>.ks-avatar {
    margin-right: 12px
}

.ks-messenger .ks-info>.ks-body>.ks-item.ks-user>.ks-name,
.ks-messenger__info>.ks-body>.ks-item.ks-user>.ks-name {
    line-height: 31px;
    color: #333
}

.ks-messenger .ks-info>.ks-body>.ks-item>.ks-name,
.ks-messenger__info>.ks-body>.ks-item>.ks-name {
    color: #858585;
    margin-bottom: 3px
}

.ks-messenger .ks-info>.ks-body>.ks-list>.ks-header,
.ks-messenger__info>.ks-body>.ks-list>.ks-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #858585;
    margin-bottom: 15px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item,
.ks-messenger__info>.ks-body>.ks-list .ks-item {
    margin-bottom: 15px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item.ks-user,
.ks-messenger__info>.ks-body>.ks-list .ks-item.ks-user {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item.ks-user>.ks-avatar,
.ks-messenger__info>.ks-body>.ks-list .ks-item.ks-user>.ks-avatar {
    margin-right: 12px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item.ks-user>.ks-name,
.ks-messenger__info>.ks-body>.ks-list .ks-item.ks-user>.ks-name {
    line-height: 31px;
    color: #333
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item>.ks-owner,
.ks-messenger__info>.ks-body>.ks-list .ks-item>.ks-owner {
    position: relative;
    top: 1px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item>.ks-owner>.ks-name,
.ks-messenger__info>.ks-body>.ks-list .ks-item>.ks-owner>.ks-name {
    display: block;
    line-height: 13px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item>.ks-owner>.ks-label-sm,
.ks-messenger__info>.ks-body>.ks-list .ks-item>.ks-owner>.ks-label-sm {
    padding: 2px;
    font-size: 9px
}

.ks-messenger .ks-info>.ks-footer,
.ks-messenger__info>.ks-footer {
    padding: 20px;
    border-top: 1px solid #dee0e1
}

.ks-messenger .ks-info>.ks-footer>.ks-item+.ks-item,
.ks-messenger__info>.ks-footer>.ks-item+.ks-item {
    margin-top: 10px
}

.ks-messenger .ks-info>.ks-footer>.ks-item>.ks-name,
.ks-messenger__info>.ks-footer>.ks-item>.ks-name {
    color: #858585;
    margin-bottom: 3px
}

.ks-messenger .ks-info>.ks-footer>.btn-block,
.ks-messenger__info>.ks-footer>.btn-block {
    margin-top: 15px
}

agm-map {
    max-width: 450px;
    overflow: hidden;
    height: 65vh;
    width: 100%;
    position: fixed;
}

@media screen and (max-width:1200px) {
    .btn.ks-messenger-info-block-toggle {
        position: static
    }

    // .ks-messenger {
    //     flex-direction: column;
    // }
    .ks-messenger .ks-messages>.ks-footer,
    .ks-messenger__messages>.ks-footer {
        padding: 15px;
    }

    .ks-messenger .ks-info.ks-open,
    .ks-messenger__info.ks-open {
        right: 0;
        -webkit-transition: right .2s ease;
        transition: right .2s ease
    }

    // .ks-messenger .ks-info,
    // .ks-messenger__info {
    //     width: 100%;
    //     margin-top: 10px;
    // }
    // .ks-messenger .ks-messages,
    // .ks-messenger__messages {
    //     margin-top: 10px;
    // }
    .ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-self>.ks-body,
    .ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-self>.ks-body {
        margin-right: 0;
    }

    .ks-messenger .ks-messages>.ks-body .ks-items>.ks-item.ks-from>.ks-body,
    .ks-messenger__messages>.ks-body .ks-items>.ks-item.ks-from>.ks-body {
        margin-left: 0;
    }
}

@media screen and (max-width:1320px) {
    agm-map {
        max-width: 32%;
    }

    .guarantor_profile p {
        word-break: break-word !important;
    }

    .table_header_title {
        width: 20% !important;
        font-size: 16px !important;
    }
}

@media screen and (max-width:991px) {
    agm-map {
        max-width: 40%;
    }

    .ks-messenger {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }

    .ks-messenger .ks-info,
    .ks-messenger__info {
        width: 100%;
        margin-top: 10px;
    }

    .ks-messenger .ks-messages,
    .ks-messenger__messages {
        // margin-top: 10px;
    }
}

@media screen and (max-width:800px) {
    .ks-messenger .ks-discussions {
        width: 100%
    }

    .ks-messenger .ks-messages.ks-open,
    .ks-messenger__messages.ks-open {
        right: 0;
        -webkit-transition: right .2s ease;
        transition: right .2s ease
    }
}

@media screen and (max-width:560px) {

    input[type="date"]:after,
    input[type="month"]:after,
    input[type="week"]:after {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    input.form-control.input_text.date-field1 {
        padding-left: 40px;
    }

    .ks-messenger .ks-messages>.ks-footer,
    .ks-messenger__messages>.ks-footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .ks-messenger .ks-messages>.ks-footer textarea,
    .ks-messenger__messages>.ks-footer textarea {
        margin-bottom: 20px
    }

    .ks-messenger__ks-messages-footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .ks-messenger__ks-messages-footer textarea {
        margin-bottom: 20px
    }
}

.img_preview_div {
    .attribute_img {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 5px #f586344d;
        margin: 15px 0;
    }

    .view_img {
        height: 60px;
        width: 60px;
        // object-fit: contain;
        cursor: pointer;
    }
}

.cyl_order_img img.view_img {
    height: 80px;
    width: 60%;
    margin-top: 10px;

    @include screen ('mobile') {
        height: 110px;
    }
}

.regulator_image {
    width: 100% !important;
    border-radius: 0 !important;
    height: 100px !important;

    .view_img {
        width: 100% !important;
        height: 100px;
    }
}

.items-container {
    width: 100%;
    height: 250px;
    border: 1px solid red;
    overflow: hidden;

    .viewport {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-anchor: none;

        .item {
            font-weight: bold;
            height: 80px;
        }

        &::-webkit-scrollbar {
            width: 5px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--ion-color-grey-tint);
            border-radius: 5px;
            display: none;
        }
    }
}

.referral_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 3.2em;
        text-transform: capitalize;
        font-weight: 700;
    }

    .refer_code {
        background-color: #f4f4f4;
        padding: 10px 20px;
        font-size: 22px;
        font-weight: 600;
        border-radius: 5px;
        text-align: center;
        width: 275px;
    }
}

.hide_last_td td:last-child {
    width: auto !important;
    display: contents;

    @include screen('tablet') {
        display: initial;
    }
}

.info_tab_list ul {
    border-bottom: 0;
}

.info_box {
    display: flex;

    @include screen('mobile') {
        display: block;
    }

    @include screen('tablet') {
        display: block;
    }

    .row {

        // display: flex;
        // height: 65px;
        // align-items: center;
        // border: 1px solid #eee;
        @include screen('mobile') {
            height: auto;
        }
    }
}

.checkbox_icon label {
    position: relative;
    z-index: 9;
    top: -5px;
}

.order_list {
    display: flex;
    justify-content: space-between;

    @include screen('mobile') {
        flex-direction: column;
    }

    ul {
        width: 25%;
        display: flex;
        flex-direction: column;
        border-bottom: none;

        @include screen('mobile') {
            width: 100%;
        }

        li {
            border-bottom: 1px solid #eee;
            margin-bottom: 10px !important;
            @extend .box_shadow;

            a {
                height: 45px;
                margin-right: 0 !important;
                display: flex;
                align-items: center;
            }
        }
    }

    .tab-content {
        background-color: #fff !important;
        border: none;
        width: 73%;
        @extend .box_shadow;

        @include screen('mobile') {
            width: 100%;
        }
    }
}

.list_filter {
    margin: 0 0 10px;

    @include screen('tablet-wide') {
        margin: 0 0 10px;
    }

    .form-control {

        // margin: 0 5px;
        @include screen('mobile') {
            margin: 0 0 10px;
        }
    }
}

// .res_filter {
//     @include screen ('tablet-wide') {
//         flex-direction: column;
//         align-items: flex-end;
//     }
// }
.spinner_loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--themeclr);
}

.live_order_list {
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        cursor: pointer;
        border: 0;

        h5 {
            margin-bottom: 0;
            color: var(--themeclr);
        }

        .badge-primary {
            background-color: var(--themeclr);
        }
    }
}


/*-------------- Responsive Css ------------*/

@media only screen and (max-width: 767px) {
    agm-map {
        position: inherit;
        max-width: 400px;
        overflow: hidden;
        height: 40vh;
        width: 100%;
    }

    .res_mt_2 {
        margin-top: 20px;
    }

    .res_mb-3 {
        margin-bottom: 20px;
    }

    .table_header {
        flex-direction: column;
        align-items: flex-start;

        h4 {
            font-size: 18px;
            margin-bottom: 15px;
        }

        .right_col {
            width: 100%;
            justify-content: space-between;
        }

        .res_filter {
            display: block !important;

            .from-control {
                width: 100%;
            }
        }
    }

    app-associate-request .icon_table::after {
        width: 180px;
    }

    app-refill-cylinder-order .icon_table .checkbox,
    app-make-cylinder-request .icon_table .checkbox,
    app-make-recycle-request .checkbox {
        top: 3px;
    }

    app-plants-refill-order .icon_table {
        width: auto;
        align-items: center;
        display: flex;
    }

    app-make-cylinder-request .checkbox {
        top: -19px;
    }

    app-refill-order app-table-layout .right_col {
        flex-direction: column;
    }

    app-refill-order .icon_table {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    app-fleet-owner .back_btn .btn_sm_primary {
        top: 0;
    }

    .refill_order {
        margin-bottom: 10px;
    }

    .sidebar-show .main::before,
    .aside-menu-show .main::before {
        background-color: transparent;
    }

    .data_text td {
        padding-left: 0 !important;
    }

    .text_center_res {
        text-align: center;
    }

    .switch-slider {
        border-color: #fff !important;
        z-index: 9;
    }

    .app-header .navbar-brand {
        position: absolute;
        top: -5px;
        left: 35%;
    }

    .box_item {
        margin-bottom: 20px;
    }

    .card-list-table {
        thead {
            display: none;
        }

        tbody {
            tr {
                flex-direction: column;
                border-bottom: 3px solid var(--themeclr) !important;
                box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
                align-items: flex-start;
                margin-bottom: 5px;

                td {
                    position: relative;
                    padding-left: 45%;
                    border-bottom: 1px solid #dfdfdf;
                    min-height: 40px;
                    width: 100%;
                    word-break: break-all;

                    &::before {
                        content: attr(data-title);
                        position: absolute;
                        left: 0;
                        top: 0;
                        padding: 12px;
                        font-weight: 600;
                        word-break: break-word;
                        max-width: 130px;
                        line-height: 16px;
                        // text-overflow: ellipsis;
                        // overflow: hidden;
                        // white-space: nowrap;
                    }
                }
            }
        }
    }

    .ngx-pagination {
        margin-bottom: 0 !important;
        text-align: center;
    }

    .card_profile {
        margin-top: 2em;
    }

    .img-box .img-box-item {
        margin-left: 0;

        img {
            width: 90%;
        }
    }

    .sales_view .img-box-item {
        img {
            width: 90%;
        }
    }

    .cropping_img canvas {
        width: 100%;
        margin: 10px 0;
    }

    .card-list-table tbody tr:nth-child(odd) {
        background-color: #fff;
    }

    .icon_table {
        position: absolute;
        bottom: 0;
        right: 0;
        width: initial;
        padding: 5px;

        // display: flex;
        // justify-content: space-between;
        a {
            font-size: 12px;
            height: 22px;
            width: 22px;
            // line-height: 22px;
            margin-right: 10px;
        }

        i {
            margin-right: 0 !important;
            position: relative;
            z-index: 9;
            background-color: #fff;
            height: 20px;
            width: 20px;
            text-align: center;
            line-height: 20px;
            border-radius: 5px;
        }

        &::after {
            content: "";
            border-bottom: 35px solid var(--themeclr);
            position: absolute;
            right: 0;
            bottom: -2px;
            border-right: 0px solid transparent;
            border-left: 20px solid transparent;
            width: 215px;
            z-index: -1;
        }

        .checkbox {
            z-index: 9;
            position: relative;
            margin-bottom: 0;
        }
    }

    .permisson-svg {
        background-color: #f0f3f8;

        @media (max-width:767px) {
            background-color: #fff;
        }
    }

    .cyl_table {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
    }

    .watermark-logo::after {
        width: 100%;
        bottom: 50px;
        display: none;
    }

    .download_img {
        background-color: #fff;
        padding: 10px 35px;
        margin-top: 5px;
        display: block;
        text-align: center;
    }

    .img_preview_div {
        .attribute_img {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 1px 5px var(--themeclr);
        }

        .view_img {
            height: 60px;
            // width: 100%;
            object-fit: cover;
        }
    }

    app-approve-recycle-request .cyl_table::after {
        min-width: 180px;
    }

    .data_table tr td:last-child {
        position: initial;
    }

    app-client .data_table tr td:last-child,
    app-notifications .data_table tr td:last-child {
        position: relative;
    }

    app-table-layout>section {
        overflow: hidden;
    }

    .referral_content h2 {
        font-size: 2em !important;
    }

    .table_content {
        p {
            word-break: break-all;
        }
    }

    .orders-layout .btn_sm_primary.bg-white {
        top: 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .res_mt_2 {
        margin-top: 20px;
    }

    .mt_2 {
        margin-top: 20px;
    }

    .sidebar-show app-profile .profile_img {
        height: 120px;
    }

    .card_profile {
        margin-top: 1em;
    }

    .img-box .img-box-item {
        margin-left: 0;
    }

    .cropping_img img-cropper {
        display: flex;
    }

    .box_item {
        margin-bottom: 20px;
    }

    .data_table tr td {
        word-break: break-all;
    }

    app-make-recycle-request .checkbox {
        margin-top: -19px !important;
    }

    .sales_view .img-box-item {
        left: 60px;
    }

    app-associate-cylinder-request .data_table tr td {
        flex: 1 auto;
        word-break: break-word;
        max-width: 120px;
    }

    .res_filter {
        flex-wrap: wrap;
        justify-content: flex-end;

        .list_filter {
            display: flex;
            margin-bottom: 10px;
        }
    }

    .icon_table {
        flex-wrap: wrap;
    }

    // app-old-cylinder-request a {
    //     height: 38px;
    // }
    .order_list_item {
        .profile_grp {
            flex-direction: column;
            align-items: flex-start !important;

            h6 {
                margin: 10px 0px 0 0px;
            }
        }
    }

    app-roles-permission-setting .btn_sm_primary {
        height: 35px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1500px) {
    .guarantor_profile p {
        margin-bottom: 0;
        word-break: break-word;
        min-width: auto;
    }

    .input-group-prepend label,
    label {
        font-size: 13px;
        margin-bottom: 0;
    }

    .data_table tr td {
        &:nth-child(6) {
            word-break: break-word;
        }
    }

    .box_item {
        .dashboard_icon {
            height: 35px;
            width: 35px;

            img {
                width: 20px;
            }
        }
    }

    .referral_content h2 {
        font-size: 2.1em !important;
    }

    app-live-orders {
        .res_flex_col {
            flex-direction: column;

            .col-lg-5,
            .col-lg-7 {
                max-width: 100%;
            }

            .col-lg-7 {
                padding-left: 0 !important;
                margin-top: 20px;
            }
        }
    }

    .timeline>li:last-child {
        margin-top: -15%;
    }

    // app-old-cylinder-request a {
    //     height: 38px;
    // }
    .order_list_item {
        .profile_grp {
            flex-direction: column;
            align-items: flex-start !important;

            h6 {
                margin: 10px 0px 0 -15px;
            }
        }
    }

    #select-button {
        right: 0 !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 766px) {
    .tab-content h3 {
        font-size: 18px;
    }

    .search_form {
        width: 100%;
        display: flex;
        margin-right: 10px;
    }

    .right_col a {
        padding: 5px 10px !important;
        width: 100%;
        white-space: nowrap;
        min-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .app-header .nav-item {
        margin: 0 10px;
    }

    .app-header .navbar-nav .dropdown-menu-right {
        width: 270px;
        right: -12px !important;
        top: 27px !important;
    }

    .app-header .navbar-brand img {
        width: 150px;
        height: 40px;
        margin-top: 10px;
    }
}

@media (min-width: 992px) {
    .referral {
        .col-lg-8 {
            max-width: 100% !important;
            margin-left: 0;
            flex: 0 0 100%;
        }
    }
}

@media screen and (min-width: 1249px) and (max-width: 1365px) {
    .vehicle_doc_group h4 {
        margin: 0 87px 25px !important;
    }

    .data_table tr td {
        word-break: break-word;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
    app-live-orders {
        .res_flex_col {
            flex-direction: column;

            .col-lg-6,
            .col-lg-6 {
                max-width: 100%;
            }

            .col-lg-6 {
                padding-left: 0 !important;
                margin-top: 20px;
            }
        }
    }

    .icon_table {
        flex-wrap: wrap;
    }

    .rating_info_request li span {
        display: flex !important;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .order_list_item {
        .profile_grp {
            flex-direction: column;
            align-items: flex-start !important;

            h6 {
                margin: 10px 0px 0 -15px;
            }
        }
    }
}

// Request View Details page css
.timeline {
    position: relative;
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 1px;
    background-color: var(--themeclr);
    left: 50%;
    margin-left: -1.5px;

    @include screen ('tablet') {
        display: none;
    }
}

.timeline:after {
    content: "\f107";
    position: absolute;
    left: 50%;
    top: 47%;
    font-family: FontAwesome;
    font-weight: 900;
    font-size: 36px;
    margin-left: -12.3px;
    color: var(--themeclr);

    @include screen ('tablet') {
        display: none;
    }

    @include screen ('mobile') {
        left: -5px;
    }
}

.timeline>li {
    margin-bottom: 20px;
    position: relative;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li>.timeline-panel {
    width: 46%;
    float: left;
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 0px;
    position: relative;

    @include screen ('tablet') {
        width: 90%;
    }
}

.table_content .timeline-body .row:nth-child(odd) {
    background-color: #fff;
    transition: var(--transition);
}

.timeline>li>.timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: " ";
}

.timeline>li>.timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
}

.timeline>li>.timeline-badge {
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -25px;
    background-color: #999999;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;

    @include screen ('tablet') {
        left: auto;
        right: 0;
        top: 15px;
    }
}

.timeline>li:last-child .timeline-badge {
    top: auto;
    bottom: 0;

    @include screen ('tablet') {
        left: 20px;
    }
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
    top: auto;
    bottom: 9px;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
    top: auto;
    bottom: 10px;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body>p,
.timeline-body>ul {
    margin-bottom: 0;
}

.timeline-body>p+p {
    margin-top: 5px;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: -5px;
    }

    ul.timeline>li>.timeline-panel {
        width: calc(100% - 35px);
        width: -moz-calc(100% - 35px);
        width: -webkit-calc(100% - 35px);
    }

    ul.timeline>li>.timeline-badge {
        left: -28px;
        margin-left: 0;
        top: 0px;
    }

    .timeline-title {
        text-align: right;
        font-size: 16px;
    }

    ul.timeline>li>.timeline-panel {
        float: right;
    }

    ul.timeline>li>.timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    ul.timeline>li>.timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }
}

.cylinder_cont {
    p {
        flex: 0 0 20%;
    }

    ul {
        flex: 0 0 80%;
        display: flex;
        justify-content: space-around;
    }
}

.order_list_hide {
    transition: all 3s linear 0s;
    height: 0;
    overflow: hidden;
    padding: 0 !important;
}

.order_list_show {
    height: auto !important;
    transition: all 3s linear 0s;
    background-color: #f0f3f8b0;
}


/*------------- Questions css ---------------*/

.items-start {
    h4 {
        @include screen ('mobile') {
            font-size: 16px;
        }
    }
}

.bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
}

.items-start {
    h4 {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }
}

.items-start-icon {
    font-size: 26px;
    align-items: center;
    display: flex;
    border-radius: 50%;
    height: 100%;

    @include screen ('mobile') {
        height: 40px;
        width: 40px;
    }

    svg {
        @include screen ('mobile') {
            position: absolute;
            font-size: 20px;
            margin-left: -6px;
        }
    }
}

ul.shadow-sm {
    cursor: auto;
}

.items-info {
    .item-info-icon {
        height: 30px;
        width: 30px;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        justify-content: center;
        display: flex;
        align-items: center;
        box-shadow: 0 1px 2px #666;
        position: relative;
        cursor: pointer;
        margin: 0 10px;

        &:hover ul {
            transform: scaleY(1);
        }
    }

    ul {
        position: absolute;
        background-color: #fff;
        height: auto;
        width: 250px;
        padding: 10px 0px;
        right: 25px;
        border: 1px solid #eee;
        border-radius: 5px;
        transition: all 0.3s linear 0s;
        transform: scaleY(0);
        transform-origin: top;
        top: 25px;
        z-index: 1;

        li {
            padding: 10px;
            border-bottom: 1px solid var(--themeclr);
        }
    }
}


/*---------- Date 29-08-20 ----------------*/

.step__title {
    position: relative;
    background: linear-gradient(to right, #3e429d 30%, #667eea 100%);
    height: 40px;
    width: 100%;
    display: grid;
    place-items: center;
    color: #fff;
    font-size: 18px;

    @include screen ('mobile') {
        font-size: 16px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
    }

    &:before {
        border-left: 20px solid #fff;
        left: -1px;
    }

    &:after {
        border-left: 20px solid #667eea;
        right: -20px;
    }

    &:not(.border-left)::after {
        border-left: 0;
    }
}

.back_btn {
    .btn_sm_primary {
        position: absolute;
        top: 7px;

        @include screen ('tablet') {
            top: 8px;
        }

        @include screen('mobile') {
            top: 8px;
        }

        margin-right: 0 !important;
        border: 0;
        color: var(--themeclr) !important;
        transition: all 0.2s ease-in 0s;
        font-size: 16px;
        font-weight: 500;
        right: 30px;

        &:hover {
            color: var(--secondaryclr) !important;
        }
    }

    &::before {
        content: "\f177";
        position: absolute;
        top: 8px;
        right: 65px;
        z-index: 9;
        font-family: FontAwesome;
        font-size: 22px;
        transition: all 0.2s ease-in 0s;
        opacity: 0;
        color: var(--secondaryclr) !important;
    }

    &:hover::before {
        opacity: 1;
        transform: translateX(-30px);
        transition: all 0.2s ease-in 0s;
    }
}

app-corporate-dashboard .tab-content h3,
app-warehouse-manager-dashboard .tab-content h3,
app-recycle-manager-dashboard .tab-content h3,
app-plant-manager-dashboard .tab-content h3 {
    text-align: center;
}

.rating_group {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .star-rating {
        position: relative;

        li {
            margin-left: 3px;
            color: var(--themeclr);
            cursor: pointer;
        }

        .rating_info {
            position: absolute;
            background-color: #fff;
            height: auto;
            width: 270px;
            padding: 10px 0px;
            right: 25px;
            border: 1px solid #eee;
            border-radius: 5px;
            transition: all 0.3s linear 0s;
            transform: scaleY(0);
            transform-origin: top;
            top: 25px;
            z-index: 1;

            li {
                padding: 10px;
                border-bottom: 1px solid var(--themeclr);

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    float: right;
                }

                span {
                    color: #333;
                }
            }
        }

        &:hover .rating_info {
            transform: scale(1);
        }
    }
}

#replay {
    height: 0;
    transition: all 0.3s linear 0s;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
}

.show_msg {
    height: auto !important;
    transform: scale(1) !important;
    transition: all 0.3s linear 0s;
}

.res_flex_quantity {
    @include screen ('mobile') {
        display: flex;
        justify-content: space-around;
    }

    .cylinder_list {
        @include screen ('mobile') {
            flex-direction: column;
        }

        span {
            margin-right: 30px;
            width: 5%;
            text-align: center;

            @include screen ('mobile') {
                margin-right: 0;
                width: 50%;
                margin-bottom: 1rem;
            }
        }
    }
}

.vehicle_doc_group {
    h4 {
        color: #3F51B5;
        font-size: 20px;
        margin: 0 132px 25px;
        padding-bottom: 25px;

        @include screen ('med-wide') {
            margin: 0 87px 25px;
        }

        @include screen ('tablet') {
            margin: 25px 0;
        }

        @include screen ('mobile') {
            margin: 30px 0 0;
            border: 0;
            padding-bottom: 5px;
        }

        // @include screen ('tablet-wide') {
        //     margin: 0 77px 25px !important;
        // }
    }

    .document_img {
        min-height: auto !important;

        // margin-bottom: 10px;
        p {
            margin-bottom: 10px;

            strong {
                font-size: 16px;
                font-weight: 500;
                color: #3F51B5;

                @include screen ('med-wide') {
                    font-size: 13px;
                }

                @include screen ('mobile') {
                    font-size: 14px;
                }
            }
        }

        figure {
            margin-bottom: 0;
            max-width: 140px;
            max-height: 100%;
            margin: 0 auto;

            @include screen ('mobile') {
                max-width: 110px;
                max-height: 100%;
            }
        }

        .view_img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        // &::before {
        //     content: "";
        //     height: 25px;
        //     background-color: var(--themeclr);
        //     position: absolute;
        //     left: 50%;
        //     top: -25px;
        //     width: 1px;
        //     @include screen ('mobile') {
        //         opacity: 0;
        //     }
        // }
    }

    .not_bdr {
        &::before {
            top: 25px;
        }
    }
}


/*------------------ Payment Getway css --------------*/

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-color: #fff;
    text-align: center;
}

.payment_logo {
    margin-bottom: 100px;
    justify-content: center;
}

.modalbox.success.animate .icon,
.modalbox.error.animate .icon {
    -webkit-animation: fall-in 0.75s;
    -moz-animation: fall-in 0.75s;
    -o-animation: fall-in 0.75s;
    animation: fall-in 0.75s;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.modalbox .redo {
    margin: 0 auto;
    font-size: 16px;
    text-transform: capitalize;
}

.modalbox.success .icon,
.modalbox.error .icon {
    position: relative;
    margin: 0 auto;
    margin-top: -75px;
    background: #4caf50;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    font-size: 48px;
    margin-bottom: 10px;
}

.modalbox.success .icon span,
.modalbox.error .icon span {
    postion: absolute;
    font-size: 4em;
    color: #fff;
    text-align: center;
    padding-top: 20px;
}

.modalbox.error .icon {
    background: #f44336;
}

.modalbox.error .icon span {
    padding-top: 25px;
}

.center {
    float: none;
    margin-left: auto;
    margin-right: auto;
    /* stupid browser compat. smh */
}

.center .change {
    clearn: both;
    display: block;
    font-size: 10px;
    color: #ccc;
    margin-top: 10px;
}

@-webkit-keyframes fall-in {
    0% {
        -ms-transform: scale(3, 3);
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0;
    }

    50% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 1;
    }

    60% {
        -ms-transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-moz-keyframes fall-in {
    0% {
        -ms-transform: scale(3, 3);
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0;
    }

    50% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 1;
    }

    60% {
        -ms-transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-o-keyframes fall-in {
    0% {
        -ms-transform: scale(3, 3);
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0;
    }

    50% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 1;
    }

    60% {
        -ms-transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-webkit-keyframes plunge {
    0% {
        margin-top: -100%;
    }

    100% {
        margin-top: 25%;
    }
}

@-moz-keyframes plunge {
    0% {
        margin-top: -100%;
    }

    100% {
        margin-top: 25%;
    }
}

@-o-keyframes plunge {
    0% {
        margin-top: -100%;
    }

    100% {
        margin-top: 25%;
    }
}

@-moz-keyframes fall-in {
    0% {
        -ms-transform: scale(3, 3);
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0;
    }

    50% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 1;
    }

    60% {
        -ms-transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-webkit-keyframes fall-in {
    0% {
        -ms-transform: scale(3, 3);
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0;
    }

    50% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 1;
    }

    60% {
        -ms-transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-o-keyframes fall-in {
    0% {
        -ms-transform: scale(3, 3);
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0;
    }

    50% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 1;
    }

    60% {
        -ms-transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes fall-in {
    0% {
        -ms-transform: scale(3, 3);
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0;
    }

    50% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 1;
    }

    60% {
        -ms-transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-moz-keyframes plunge {
    0% {
        margin-top: -100%;
    }

    100% {
        margin-top: 15%;
    }
}

@-webkit-keyframes plunge {
    0% {
        margin-top: -100%;
    }

    100% {
        margin-top: 15%;
    }
}

@-o-keyframes plunge {
    0% {
        margin-top: -100%;
    }

    100% {
        margin-top: 15%;
    }
}

@keyframes plunge {
    0% {
        margin-top: -100%;
    }

    100% {
        margin-top: 15%;
    }
}

.rating_info_request {
    display: flex;
    flex-wrap: wrap;

    li {
        flex: 0 0 50%;
        margin: 10px 0;

        @include screen ('mobile') {
            flex: 0 0 100%;
        }

        span {
            @include screen ('mobile') {
                display: flex;
                width: 100%;
            }

            @include screen ('med-wide') {
                display: flex !important;
                width: 100%;
                margin-left: 2px;
                margin-top: 5px;
            }
        }

        &:last-child {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

.guarantor_profile {
    .row {
        border-bottom: 1px solid #eee;
    }

    .demo {
        cursor: pointer;

        span {
            font-size: 22px;
            // margin-left: auto;
        }

        .star-rating ul {
            float: right;
        }
    }
}

.info-group {
    align-items: stretch;

    .col-lg-4 {
        @include screen ('mobile') {
            margin-bottom: 10px;
        }

        @include screen ('tablet') {
            margin-bottom: 10px;
        }

        .bg-white {
            min-height: 100%;
            cursor: pointer;

            &:hover a::after {
                opacity: 1;
                transform: translateX(40px);
            }
        }
    }

    h4 {
        font-size: 18px;

        a {
            color: rgb(62 66 157 / 88%);
            position: relative;

            &::after {
                content: "\f178";
                font-family: FontAwesome;
                right: 0%;
                position: absolute;
                transition: all 0.3s linear 0s;
                opacity: 0;
            }
        }
    }

    .info-list {
        margin-bottom: 5px;

        li {
            padding: 7px 15px;
            border-bottom: 1px solid rgb(245 134 52 / 34%);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .d-flex {
                align-items: center;

                div {
                    border: 1px solid #eee;
                    height: 35px;
                    width: 35px;
                    background-color: rgb(245 134 52 / 33%);
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 1.5em;
                    }
                }
            }

            p {
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 500;
                color: #1c2833c7 !important;
            }

            h4 {
                color: #3e429d;
                margin: 0;
                font-weight: 600;
                font-size: 20px;
                letter-spacing: .5px;
            }

            @include screen ('mobile') {
                flex: 0 0 100%;
                border-bottom: 1px solid rgb(245 134 52 / 33%);
                border-right: 0;
            }
        }
    }
}

.view_more {
    cursor: pointer;

    svg {
        transition: all 0.3s linear 0s;
        animation: downarrow1 1s ease-in-out infinite;
    }
}

.review_Grp {
    display: none;
}

.arrow_up svg {
    transform: rotate(180deg);
    transition: all 0.3s linear 0s;
    animation: unset;
}

@keyframes downarrow1 {

    0%,
    100% {
        transform: translateY(0, 0) rotate(0deg);
    }

    50% {
        transform: translateY(10px) rotate(0deg);
    }
}

.order_grp {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 10px 0;

    .col-lg-6 {
        margin-bottom: 20px;
    }

    .bg-white {
        padding: 15px;
        min-height: 100%;
    }
}

ul.nav.nav-tabs {
    border-bottom: none;
}

.profile_info ul {
    margin-top: 20px;

    @include screen ('tablet') {
        margin-top: 55px;
    }

    @include screen ('mobile') {
        margin-top: 55px;
    }
}


/*------- Fleet Owner view page --------*/

.fleet_view {
    .fleet_card {
        height: 230px;
        margin-bottom: 10px;
        overflow: hidden;
    }

    .flex_dir {
        @include screen ('mobile') {
            flex-direction: column;
        }
    }
}

.fleet_card .data_table tr td:last-child {
    position: relative;
}

.card_item .row .col-md-4 {
    max-width: 100% !important;
    flex: 0 0 100%;
    padding: 10px 30px;

    figure {
        margin-bottom: 0;
    }
}

.card_item .row .col-md-4:first-child {
    display: none;
}

.card_item .table_content,
.card_item .table_content .row {
    display: flex;
    width: 100%;
    padding: 0;
    align-items: center;
    border: 0;
}

app-fleet-owner .card {
    height: auto;
}

.ml_auto {
    @include screen ('mobile') {
        margin-left: auto;
    }
}

.refresh_msg {
    font-size: 18px;
    font-weight: 400;
    transition: all 0.3s ease-in 0s;
    margin-right: 0px;

    &:hover {
        color: var(--secondaryclr);
    }
}

.custom_breadcrumb {
    p {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        max-width: 350px;
        padding: 10px 15px;
        position: absolute;
        right: 30px;
        top: 0px;
        border-radius: 5px;

        @include screen ('mobile') {
            position: relative;
            top: 5px;
            margin-left: auto;
            right: -20px;
            padding: 5px 15px;
        }
    }

    .breadcrumb {
        padding: 12px 30px;
        border-bottom: 0;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

        .breadcrumb-item {
            color: #73818f;
        }
    }
}

.table_header_title {
    font-size: 18px;
    word-break: break-word;
    width: 30%;

    @include screen ('mobile') {
        width: 100% !important
    }
}

.view_details {
    transition: all 0.3s ease-in-out 0s;
    height: 0;
    transform: scaleY(0);
    transform-origin: top;
}

.view_fleet_det {
    height: auto;
    transform: scaleY(1);
    transition: all 0.3s ease-in-out 0s;
}

.order_details_item {
    padding-left: 15px;
    flex-wrap: wrap;

    @include screen ('mobile') {
        padding-left: 20px;
    }

    @include screen ('tablet') {
        padding-left: 20px;
    }

    .row {
        padding-left: 32px;
        width: 100%;

        .d-flex {
            @include screen ('mobile') {
                display: flex !important;
                flex-wrap: wrap;
                margin-bottom: 10px;
            }
        }

        @include screen ('mobile') {
            padding-left: 32px;
        }

        p {
            @include screen ('mobile') {
                margin-bottom: 0;
                margin-left: 0 !important;
            }
        }
    }

    .recipient_title {
        padding-left: 32px;
        font-size: 16px;

        @include screen ('mobile') {
            padding-left: 32px;
        }
    }
}

.fleet_accordian {
    padding: 15px;
    border: 2px solid var(--themeclr);
    border-top: none;

    tr {
        background-color: #fff !important;
    }
}

.client_order_info {
    border-radius: 50%;
    background-color: #f5863457;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

app-fleet-owner .pagination {
    padding-bottom: 15px;
}

.orders-layout {
    .nav-tabs {
        border-bottom: 0;

        @include screen ('mobile') {
            margin-top: 35px;
        }
    }
}

.order_type_back_btn .bg-white {
    min-height: initial !important;
    padding: .375rem .75rem;
}

.tab_no_record {
    margin-bottom: 0;
    color: var(--themeclr);
    font-size: 1rem !important;
    padding-top: 20px;
    font-weight: 600;
}

.cylinder_stock_quantity {
    .card-header {
        font-size: 18px;
        font-size: 16px;
        font-weight: 600;
        padding: 10px;
        background-color: rgba(62, 66, 157, 0.8);
        color: #fff;
    }

    table {
        thead th {
            background-color: rgba(62, 66, 157, 0.8);
            color: #fff;
            padding: 15px 10px;
            font-weight: 600;
            font-size: 14px;

            &:first-child {
                visibility: hidden;
            }
        }

        tr {
            display: flex;

            th {
                flex: 1;
                border-bottom: 0;
                border-top: 0;
            }

            td {
                flex: 1;
                background-color: #fff;
                border-color: #c8ced36b;
                border-left-color: #c8ced36b !important;
                font-size: 14px;

                span {
                    color: var(--themeclr);
                    font-size: 16px;
                }
            }
        }
    }
}

.cylinder_stock_quantity table td,
.cylinder_stock_quantity table th {
    padding: 10px;
    text-align: center;
}

.associate_checkbox {
    .checkbox_icon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px 15px;
        font-size: 0.875rem;
        background-color: #f58634;
        border-radius: 5px;
        margin-left: auto;
        margin-right: 10px;
        font-weight: 600;
        color: #fff;

        label {
            top: 0;
            margin-bottom: 0;
        }
    }
}


/*------------------------ Faq Static Design Css Start ---------------------*/

.accordion a {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding: 1rem 3rem 1rem 1rem;
    color: #7288a2;
    font-size: 1.15rem;
    font-weight: 500;
    border-bottom: 1px solid #e5e5e5;
}

.accordion a:hover,
.accordion a:hover::after {
    cursor: pointer;
    color: var(--themeclr);
}

.accordion a:hover::after {
    border: 1px solid var(--themeclr);
}

.accordion a.active {
    color: var(--themeclr);
    border-bottom: 1px solid var(--themeclr);
}

.accordion a::after {
    font-family: 'FontAwesome';
    content: '\f067';
    position: absolute;
    float: right;
    right: 1rem;
    font-size: 1rem;
    color: #3e429d;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #3e429d;
    text-align: center;
    line-height: 18px;
}

.accordion a.active::after {
    font-family: 'FontAwesome';
    content: '\f068';
    color: var(--themeclr);
    border: 1px solid var(--themeclr);
}

.accordion .content {
    display: none;
    padding: 1rem;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
}

.accordion .content p,
ol li {
    font-size: 1rem;
    font-weight: 400;
}

// *-------------- Date - 21-10-2020 Custom select Dropdown -------------------------*//
.badge_status {
    border: 1px solid transparent;
    padding: 0 15px;
    border-radius: 20px;
}

.cselect {
    position: relative;
    cursor: pointer;
    display: inline-block;

    input {
        background: #ccc;
        border: 1px solid #f6f6f6;
        background-clip: padding-box;
        /* sharp borders for mozila*/
        border-radius: 20px;
        padding: 2px 10px;
        width: 60%;
        outline: none;
        cursor: pointer;
        text-align: center;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #fff !important;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: #fff !important;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: #fff !important;
        }

        @include screen ('tablet') {
            width: 100%;
        }

        @include screen ('tablet-wide') {
            min-width: 110px;
            text-overflow: ellipsis;
        }

        @include screen ('med-wide') {
            width: 70%;
        }
    }

    ul {
        margin-top: 3px;
        margin-bottom: 0;
        width: 100%;
        display: none;
        position: absolute;
        z-index: 999;
        border-radius: .2rem;
        font-size: 13px;
        padding: .3125rem 0;
        box-shadow: 0 6px 12px rgba(0, 0, 0, .176);
        border-color: #e1e1e1;
        right: 0;
        background-color: #fff;

        @include screen ('tablet') {
            width: 100%;
        }

        li {
            padding: 5px 15px;
            list-style: none;
            text-align: left;
            position: relative;

            &:hover {
                color: #fff;
            }
        }
    }
}

input.optionbg1::placeholder,
input.optionbg2::placeholder,
input.optionbg3::placeholder,
input.optionbg4::placeholder {
    color: #fff;
}

.select-value::after {
    font-family: "FontAwesome";
    content: "\f078";
    position: absolute;
    right: 1px;
    font-size: 0.9rem;
    color: #000;
    padding: 5px;
    line-height: 18px;
    top: 0;
}

.select-value input {
    text-align: left;
}

.optionbg1 {
    background-color: #ff8400 !important;
    color: #fff;
    transition: all 0.2s ease-in-out 0s;
}

.optionbg2 {
    background-color: #dc3545 !important;
    color: #fff;
    transition: all 0.2s ease-in-out 0s;
}

.optionbg3 {
    background-color: #448fea !important;
    color: #fff;
    transition: all 0.2s ease-in-out 0s;
}

.optionbg4 {
    background-color: #32c766 !important;
    color: #fff;
    transition: all 0.2s ease-in-out 0s;
}

.optionbg5 {
    background-color: #fff !important;
    color: #000;
    transition: all 0.2s ease-in-out 0s;
}

.bg1:hover {
    background-color: #ff8400;
}

.bg2:hover {
    background-color: #dc3545;
}

.bg3:hover {
    background-color: #448fea;
}

.bg4:hover {
    background-color: #32c766 !important;
}


/*------------ Live Order Css Date-26-10-2020 ------------*/

//UL
.ui-tree {
    margin: 0;
    padding: 0;
    list-style: none;

    // LI
    li {
        position: relative;
        line-height: 2em;
        margin: 10px 0;

        .order_info p {
            flex: 1;
        }
    }

    // Level 2
    // -------------------------
    ul {
        position: relative;
        margin: 0 0 0 1em;
        padding: 0;
        list-style: none;

        // vertical line
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1px;
            //border-left: 1px dotted rgba(0, 0, 0, 0.3);
        }

        li {
            padding-left: (1em + 0.5); // indentation + .5em
        }

        // Level 3+
        // -------------------------
        ul {
            margin-left: (1em / 2);
            /* half the indentation */
        }
    }
}


/*--------------- Invoice Css ----------------*/

.invoice_order_list {
    li {
        border-bottom: 1px solid #f8a96e66;
        min-height: 20px;
        padding: 10px 0px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 13px !important;
    }

    span {
        font-weight: 400;
        font-size: 13px !important;
        width: 65%;
    }

    strong {
        font-size: 13px !important;
    }
}

.order_invice_img {
    background-color: #f5863473;
    border-radius: 20px;
    padding: 5px;
    height: 35px;
    width: 35px;
    margin-left: -41px;
}

ngx-intl-tel-input {
    .separate-dial-code {
        width: 100%;

        input {
            height: 38px;
        }
    }
}

app-walk-ins-order {
    .checkbox {
        position: relative;
        z-index: 9;

        span {
            z-index: 0;
        }

        input {
            display: block;
            opacity: 0;
        }
    }
}

.cd-point {
    font-size: 18px;
}

app-walk-ins-order label.checkbox.off-checkbox {
    float: left !important;
    margin-left: -15px;
}

app-terms-conditions .card-group .card_box .card_description p {
    text-align: left;
}

.back_btn::before {
    top: 7px;
    right: 65px;

    @include screen ('mobile') {
        top: 7px;
    }
}

@media (max-width: 767px) {
    app-associate-request {
        .cselect ul {
            width: 100%;
            margin-top: 0;
        }
    }

    app-clients-refill-order .back_btn .btn_sm_primary {
        top: 7px;
    }
}

a.status-dot {
    position: absolute;
    top: 27px;
    right: -4px;
    background-color: #fff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    font-size: 15px;
}

.fa.fa-circle.offline {
    color: #a3a7ab !important;
}

.fa-circle:before {
    content: "\f111";
    font-size: 17px;
}

.my-drop {
    font-size: 16px !important;
    max-width: 80% !important;
    height: calc(2.5em + 0.75rem + 2px) !important;
    display: block !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #5c6873 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #e4e7ea !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

img.navbar-brand-full {
    height: 58px;
    margin-left: 2px;
    margin-top: -5px;
    image-rendering: -webkit-optimize-contrast;
}

.md-drppicker .btn.btn-default.clear {
    background-color: red !important;
    border-radius: 3px;
    color: white;
    margin-right: 10px;
}

.md-drppicker .btn.btn-default.clear>svg {
    display: none;
}