// Variable overrides
:root {
    --themeclr : #f58634;
    --secondaryclr : #3e429d;
    --transition: all 0.2s ease-in-out 0s;
}

$shadow: 0 2px 3px rgba(0, 0, 0, .2);
$gray-border: darken(#f8f8f8, 10%);
$gray-light: rgba(0, 0, 0, .35);


// Media Queries 

@mixin screen($size) {
    $desktop: "(min-width: 1024px)";
    $tablet: "(min-width: 768px) and (max-width: 1023px)";
    $tablet-wide: "(min-width: 1024px) and (max-width: 1248px)";
    $med-wide: "(min-width: 1366px) and (max-width: 1600px)";
    $mobile: "(max-width: 767px)";
    @if $size == desktop {
      @media only screen and #{$desktop} {
        @content;
      }
    }
    @else if $size == tablet {
      @media only screen and #{$tablet} {
        @content;
      }
    }
    @else if $size == tablet-wide {
      @media only screen and #{$tablet-wide} {
        @content;
      }
    }
    @else if $size == med-wide {
      @media only screen and #{$med-wide} {
        @content;
      }
    }
    @else if $size == mobile {
      @media only screen and #{$mobile} {
        @content;
      }
    }
    @else {
      @media only screen and #{$size} {
        @content;
      }
    }
  }


